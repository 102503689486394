var core = require('./Core');
var $ = require('jquery');
var Slider = require('./components/Slider/Slider');
var slickMobile = false;

/**
 * document ready functions
 */

core.$document.ready(function() {
    square($('.download'));
    square($('.gallery'));
    $(".paper").last().css("margin-bottom", "-200px");

    new Slider('#voices1', {
        arrows: false,
        dots: true,
        appendDots: $('#dots1')
    });
    new Slider('#voices2', {
        arrows: false,
        dots: true,
        appendDots: $('#dots2')
    });

    $(".navcont li").on('mouseenter mouseleave', function(e) {
        if ($('ul', this).length) {
            var elm = $('ul:first', this);
            var off = elm.offset();
            var l = off.left;
            var w = elm.width();
            var docH = $(window).height();
            var docW = $(window).width();
            var isEntirelyVisible = (l + w <= docW);

            if (!isEntirelyVisible) {
                $('.nav-level-1', this).addClass('nav-level-1-left');
            } else {
                $('.nav-level-1', this).removeClass('nav-level-1-left');
            }
        }
    });


    var active = false;
    $('#search').click(function() {
        if (active) {
            hideSearch();
            active = false;
        } else {
            showSearch();
            active = true;
        }
    });
    $(function() {
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000);
            return false;
          }
        }
      });
    });
    $("#search-input-desktop").focusout(function() {
        hideSearch();
        if (active) {
            active = false;
        }
    });
    $("#search-input-desktop").keyup(function(e) {
        if (e.keyCode === 27) {
            hideSearch();
            active = false;
        }
    });
    $("a .toplevel").on('mouseenter', function(e) {
        hideSearch();
        active = false;
    });
    $("#close").click(function() {
            $(".moredates").hide(200);
            $(".showdates").show(200);
    });
    $(".showdates").click(function() {
            $(".moredates").show(400);
            $(this).hide();
    });
    $(".mail").click(function(e) {
        e.stopPropagation();
    });
    var last;
    $('.row').click(function(e) {
        if($(this).hasClass('active')) {
            $(this).toggleClass("active");
            $(this).find('.fa').removeClass('fa-close');
            $(this).find('.fa').addClass('fa-chevron-down');
            $(this).find('.detail').hide(400);
            last = undefined;
            return false;
        }
        if(typeof last != 'undefined') {
            last.toggleClass("active");
            last.find('.fa').removeClass('fa-close');
            last.find('.fa').addClass('fa-chevron-down');
            last.find('.detail').hide();
        }
        $(this).find('.fa').removeClass('fa-chevron-down');
        $(this).find('.fa').addClass('fa-close');
        $(this).find('.detail').show(400);
        $(this).toggleClass("active");
        last = $(this);
    });

    var firstChars = [];
    $('.lastname').each(function(index) {
        var char = $(this).text().slice(0,1);
        if(firstChars.indexOf(char) === -1) {
            firstChars.push(char);
        }
    });
    checkIfInArray(firstChars);

    var lastChar;
    var curFilter;
    checkFilter(curFilter);
    $('.filter').find('li').click(function() {
        $('.row').show();
            curFilter = $(this).text().slice(0,1);
            checkFilter(curFilter);
            if($(this).is('#all')) {
            } else {
                $('.lastname').each(function() {
                    var char = $(this).text().slice(0,1);
                    if(char != curFilter) {
                        $(this).closest('.row').hide();

                    }
                });
            }
            if(typeof lastChar != 'undefined') {
                lastChar.removeClass('active');
            }
            $(this).addClass('active');
            lastChar = $(this);  
    });
    var $window = $(window); //You forgot this line in the above example

// $(window).scroll(function () { 
//    $('.scroll').css({
//       'top' : -($(this).scrollTop()/3) - 200 +"px"
//    }); 
// });  
});

function checkIfInArray(array) {
    $('.filter').find('li').not('#all').each(function(index) {
        if (array.indexOf($(this).text()) === -1) {
            $(this).hide();
        }
    });
};

function checkFilter(filter) {
    if ((typeof filter === 'undefined') || ($(filter).is('#all'))) {
        $('#all').addClass('active');
        $('.row').show();
    } else {
        $('#all').removeClass('active');
    }
};

function showSearch() {
    $('.tooltip').css('opacity', '1');
    $("#search-input-desktop").focus();
};

function hideSearch() {
    $('.tooltip').css('opacity', '0');
};

jQuery(document).ready(function($) {
    $('.gallerycontainer').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Lade #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1],
            tCounter: '<span class="mfp-counter">%curr% von %total%</span>'
        },
        image: {
            tError: '<a href="%url%">Das Bild #%curr%</a> konnte nicht geladen werden.'
        },
        zoom: {
            enabled: true,
            duration: 300, // don't foget to change the duration also in CSS
            opener: function(element) {
                return element.find('.gallery');
            }
        }
    });
});

/**
 * document resize functions 
 */

core.$window.resize(function() {
    square($('.download'));
    square($('.gallery'));
    if (core.$window.innerWidth() <= 768) {
        if (!slickMobile) {
            slickMobile = new Slider('[data-slider="mobile"]');
        }
    } else {
        if (slickMobile) {
            slickMobile.destroy();
            slickMobile = false;
        }
    }
});

function isElementVisible(el) {
    var rect = el.getBoundingClientRect(),
        vWidth = window.innerWidth || doc.documentElement.clientWidth,
        vHeight = window.innerHeight || doc.documentElement.clientHeight,
        efp = function(x, y) {
            return document.elementFromPoint(x, y)
        };

    // Return false if it's not in the viewport
    if (rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight)
        return false;

    // Return true if any of its four corners are visible
    return (
        el.contains(efp(rect.left, rect.top)) || el.contains(efp(rect.right, rect.top)) || el.contains(efp(rect.right, rect.bottom)) || el.contains(efp(rect.left, rect.bottom))
    );
};

function square(el) {
    $(el).css("height", $(el).outerWidth());
};
